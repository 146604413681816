<template>
	<div class="register">
		<div class="box-card">
			<el-tabs v-model="activeName">
				<el-tab-pane label="Register" name="first" >
					<el-form v-if="activeName == 'first'" :model="registerForm" :rules="registerRules" ref="registerRef" label-width="120px"
					 label-position="right" show-message>
            <el-form-item label="Phone" prop="mobile">
              <el-input v-model="registerForm.mobile" placeholder="Please input Phone number" type="text"></el-input>
            </el-form-item>
            <el-form-item label="Email" prop="email">
              <el-input v-model="registerForm.email" placeholder="Please input Email" type="text"></el-input>
            </el-form-item>
						<el-form-item label="Password" prop="password">
							<el-input v-model="registerForm.password" placeholder="Please input password" type="password"></el-input>
						</el-form-item>
						<el-form-item label="Confirm" prop="checkPass">
							<el-input v-model="registerForm.checkPass" placeholder="Please input Confirm password" type="password"></el-input>
						</el-form-item>
						<el-form-item label="Captcha" prop="code">
							<el-input v-model="registerForm.code" placeholder="Please input captcha" maxlength="4">
								<template slot="append">
									<img :src="captcha.img" mode class="captcha" @click="getCode" />
								</template>
							</el-input>
						</el-form-item>
					</el-form>
					<div class="xy" @click="check">
						<div class="xy-wrap">
							<div class="iconfont" :class="ischecked ? 'iconxuanze-duoxuan' : 'iconxuanze'"></div>
							<div class="content">
                Read and agree
								<b @click.stop="getAggrement">《Service agreement》</b>
							</div>
						</div>
						<div class="toLogin" @click="toLogin">You already have an account, log in now</div>
					</div>
					<el-button @click="register">Register Now</el-button>
				</el-tab-pane>
			</el-tabs>
			<el-dialog :title="agreement.title" :visible.sync="aggrementVisible" width="60%" :before-close="aggrementClose"
			 :lock-scroll="false" center>
				<div v-html="agreement.content" class="xyContent"></div>
			</el-dialog>
		</div>
		<!-- 浮层区 -->
		<div class="floatLayer-wrap" v-show="is_show && reward" :style="{width:bgWidth,height:bgHeight}">
			<div class="reward-wrap">
				<img :src="$util.img('upload/uniapp/register_reward_img.png')" mode="widthFix" class="bg-img-head"></img>
				<img :src="$util.img('upload/uniapp/register_reward_money.png')" mode="widthFix" class="bg-img-money"></img>
				<img :src="$util.img('upload/uniapp/register_reward_head.png')" mode="widthFix" class="bg-img"></img>
				<div class="wrap">
					<div>
						<div class="reward-content">
							<div class="reward-item" v-if="reward && reward.point > 0">
								<div class="head">Bonus points</div>
								<div class="content">
									<div class="info">
										<div>
											<span class="num">{{ reward.point }}</span>
											<span class="type">Score</span>
										</div>
										<div class="desc">Used to cash out or exchange goods when placing an order</div>
									</div>
									<div class="tip" @click="closeRewardPopup('point')">View now</div>
								</div>
							</div>
							<div class="reward-item" v-if="reward && reward.growth > 0">
								<div class="head">Growth value</div>
								<div class="content">
									<div class="info">
										<div>
											<span class="num">{{ reward.growth }}</span>
											<span class="type">Growth value</span>
										</div>
										<div class="desc">Used to upgrade member level</div>
									</div>
									<div class="tip" @click="closeRewardPopup('growth')">View now</div>
								</div>
							</div>
							<div class="reward-item" v-if="reward && reward.balance > 0">
								<div class="head">Red packet reward</div>
								<div class="content">
									<div class="info">
										<div>
											<span class="type">₵</span>
                      <span class="num">{{ reward.balance }}</span>
										</div>
										<div class="desc">Available when placing an order that cannot be withdrawn</div>
									</div>
									<div class="tip" @click="closeRewardPopup('balance')">View now</div>
								</div>
							</div>
							<div class="reward-item" v-if="reward && reward.coupon_list.length > 0">
								<div class="head">Coupon reward</div>
								<div class="content" v-for="(item, index) in reward.coupon_list" :key="index">
									<div class="info">
										<div>
											<span class="num coupon-name">{{ item.coupon_name }}</span>
										</div>
										<div class="desc" v-if="item.at_least > 0">
											need {{ item.at_least }}{{ item.type == 'discount' ? ' to discount ' + item.discount + '/10' : '-' + item.money }}
										</div>
										<div class="desc" v-else>无门槛，{{ item.type == 'discount' ? ' to discount ' + item.discount + '/10' : '-' + item.money }}</div>
									</div>
									<div class="tip" @click="closeRewardPopup('coupon')">View now</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="close-btn" @click="closeRewardPopup()">
					<i class="iconfont iconguanbi"></i>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
	import {
		getRegisiterAggrement,
		registerConfig,
    registerEmail,
		registerMobileCode,
		getRegisterReward
	} from '@/api/auth/register';
	import {
		captcha
	} from '@/api/website';
	export default {
		name: 'register',
		components: {},
		data() {
			var checkPassValidata = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('Please enter the password again'));
				} else if (value !== this.registerForm.password) {
					callback(new Error('The two passwords entered are not the same.!'));
				} else {
					callback();
				}
			};
			let self = this;
			var passwordValidata = function(rule, value, callback) {
				let regConfig = self.registerConfig;
				if (!value) {
					return callback(new Error('Please input password'));
				} else {
					if (regConfig.pwd_len > 0) {
						if (value.length < regConfig.pwd_len) {
							return callback(new Error('Password length cannot be less than' + regConfig.pwd_len + ''));
						} else {
							callback();
						}
					} else {
						if (regConfig.pwd_complexity != '') {
							let passwordErrorMsg = 'The password must contain ',
								reg = '';
							if (regConfig.pwd_complexity.indexOf('number') != -1) {
								reg += '(?=.*?[0-9])';
								passwordErrorMsg += 'Number ';
							} else if (regConfig.pwd_complexity.indexOf('letter') != -1) {
								reg += '(?=.*?[a-z])';
								passwordErrorMsg += '、Lowercase letters';
							} else if (regConfig.pwd_complexity.indexOf('upper_case') != -1) {
								reg += '(?=.*?[A-Z])';
								passwordErrorMsg += '、capital';
							} else if (regConfig.pwd_complexity.indexOf('symbol') != -1) {
								reg += '(?=.*?[#?!@$%^&*-])';
								passwordErrorMsg += '、Special character';
							} else {
								reg += '';
								passwordErrorMsg += '';
							}

							if (reg.test(value)) {
								return callback(new Error(passwordErrorMsg));
							} else {
								callback();
							}
						}
					}
				}
			};
			var isMobile = (rule, value, callback) => {
				if (!value) {
					return callback(new Error("Mobile phone number cannot be empty"))
				} else {
					const reg = /^(\+233|0)(20|50|24|54|27|57|26|56|23|28|55|59)\d{7}$/

					if (reg.test(value)) {
						callback()
					} else {
						callback(new Error("Please enter the correct mobile phone number"))
					}
				}
			};
			return {
				registerForm: {
				  email: '',
					username: '',
					password: '',
					checkPass: '',
					code: '',
					mobile: '',
					vercode: '',
					dynacode: '',
					key: ''
				},
				registerRules: {
          email: [
            { required: true, message: 'Please input email', trigger: 'blur' },
            { type: 'email', message: 'Please enter the correct email address', trigger: ['blur', 'change'] }
          ],
          mobile: [{
            required: true,
            validator: isMobile,
            trigger: "blur"
          }],
					password: [{
						required: true,
						validator: passwordValidata,
						trigger: 'blur'
					}],
					checkPass: [{
						required: true,
						validator: checkPassValidata,
						trigger: 'blur'
					}],
					code: [{
						required: true,
						message: 'Please enter a verification code',
						trigger: 'blur'
					}]
				},
				dynacodeData: {
					seconds: 120,
					timer: null,
					codeText: "Please enter the SMS dynamic code",
					isSend: false
				}, // 动态码
				ischecked: false,
				agreement: '',
				aggrementVisible: false,
				captcha: {
					// 验证码
					id: '',
					img: ''
				},
				registerConfig: {
					register: ''
				},
				activeName: "first", // tab切换
				reward: null,
				is_show: false,
				bgWidth: '',
				bgHeight: ''
			};
		},
		created() {
			this.getCode();
			this.regisiterAggrement();
			this.getRegisterConfig();
			this.getRegisterReward();
			this.bgWidth = document.documentElement.clientWidth + "px";
			this.bgHeight = document.documentElement.clientHeight + "px";
		},
		methods: {
			closeRewardPopup(type) {
				this.is_show = false;

				switch (type) {
					case 'point':
						this.$router.push('/member/my_point');
						break;
					case 'balance':
						this.$router.push('/member/account');
						break;
					case 'growth':
						this.$router.push('/member/index');
						break;
					case 'coupon':
						this.$router.push('/member/my_coupon');
						break;
					default:
						this.$router.push('/member/index');
						this.is_show = false;
				}
			},
			getRegisterReward() {
				getRegisterReward()
				.then(res=>{
					if (res.code >= 0) {
						let data = res.data;
						if (data.is_use == 1 && (data.value.point > 0 || data.value.balance > 0 || data.value.growth > 0 || data.value.coupon_list.length > 0 )) {
							this.reward = data.value;
						}
					}
					console.log(res,'getRegisterReward')
				})
				.catch(err=>{
					console.log(err,'getRegisterReward')
				})
			},
			sendMobileCode(formName) {
				if (this.dynacodeData.seconds != 120) return
				this.$refs[formName].clearValidate("dynacode")

				this.$refs[formName].validateField("mobile", valid => {
					if (valid) {
						return false
					}
				})
				this.$refs[formName].validateField("vercode", valid => {
					if (!valid) {
						registerMobileCode({
								mobile: this.registerForm.mobile,
								captcha_id: this.captcha.id,
								captcha_code: this.registerForm.vercode
							})
							.then(res => {
								if (res.code >= 0) {
									this.registerForm.key = res.data.key
									if (this.dynacodeData.seconds == 120 && this.dynacodeData.timer == null) {
										this.dynacodeData.timer = setInterval(() => {
											this.dynacodeData.seconds--
											this.dynacodeData.codeText = 'It can be reacquired after ' + this.dynacodeData.seconds + "s"
										}, 1000)
									}
								}
							})
							.catch(err => {
								this.$message.error(err.message)
							})
					} else {
						return false
					}
				})
			},
			handleClick(tab, event) {
				if (this.activeName == "first") {
					this.loginMode = "account"
				} else {
					this.loginMode = "mobile"
				}
			},
			check() {
				this.ischecked = !this.ischecked;
			},
			toLogin() {
				this.$router.push('/login');
			},
			//  获取注册配置
			getRegisterConfig() {
				registerConfig()
					.then(res => {
						if (res.code >= 0) {
							this.registerConfig = res.data.value;
							if (this.registerConfig.register == '') {
								this.$message({
									message: 'Registration is not enabled for the platform',
									type: 'warning',
									duration: 2000,
									onClose: () => {
										this.$router.push('/');
									}
								});
							}
						}
					})
					.catch(err => {
						console.log(err.message)
					});
			},
			// 账号密码注册
			register() {
				this.$refs.registerRef.validate(valid => {
					if (valid) {
						if (!this.ischecked) {
							return this.$message({
								message: 'Please read the agreement first and check it.',
								type: 'warning'
							});
						}
						var data = {
              email: this.registerForm.email.trim(),
              mobile: this.registerForm.mobile,
							password: this.registerForm.password
						};
						if (this.captcha.id != '') {
							data.captcha_id = this.captcha.id;
							data.captcha_code = this.registerForm.code;
						}
						this.$store
							.dispatch('member/registerNew_token', data)
							.then(res => {
								if (res.code >= 0) {
									if (this.reward) {
										this.is_show = true;
									} else {
										this.$router.push('/member/index');
									}
								}
							})
							.catch(err => {
								this.$message.error(err.message);
								this.getCode();
							});
					} else {
						return false;
					}
				});
			},
      // 邮箱密码注册
      registerEmail() {
        this.$refs.emailRuleForm.validate(valid => {
          if (valid) {
            if (!this.ischecked) {
              return this.$message({
                message: 'Please read the agreement first and check it.',
                type: 'warning'
              });
            }
            var data = {
              email: this.registerForm.email.trim(),
              password: this.registerForm.password
            };
            if (this.captcha.id != '') {
              data.captcha_id = this.captcha.id;
              data.captcha_code = this.registerForm.code;
            }
            this.$store
                .dispatch('member/registerEmail_token', data)
                .then(res => {
                  if (res.code >= 0) {
                    if (this.reward) {
                      this.is_show = true;
                    } else {
                      this.$router.push('/member/index');
                    }
                  }
                })
                .catch(err => {
                  this.$message.error(err.message);
                  this.getCode();
                });
          } else {
            return false;
          }
        });
      },
			// 手机号注册
			registerMobile() {
				this.$refs.mobileRuleForm.validate(valid => {
					if (valid) {
						if (!this.ischecked) {
							return this.$message({
								message: 'Please read the agreement first and check it.',
								type: 'warning'
							});
						}
						var data = {
							mobile: this.registerForm.mobile,
							key: this.registerForm.key,
							code: this.registerForm.dynacode
						};
						if (this.captcha.id != '') {
							data.captcha_id = this.captcha.id;
							data.captcha_code = this.registerForm.code;
						}
						this.$store
							.dispatch('member/registerMobile_token', data)
							.then(res => {
								if (res.code >= 0) {
									if (this.reward) {
										this.is_show = true;
									} else {
										this.$router.push('/member/index');
									}
								}
							})
							.catch(err => {
								this.$message.error(err.message);
								this.getCode();
							});
					} else {
						return false;
					}
				});
			},
			aggrementClose() {
				this.aggrementVisible = false;
			},
			// 获取协议
			regisiterAggrement() {
				getRegisiterAggrement()
					.then(res => {
						if (res.code >= 0) {
							this.agreement = res.data;
						}
					})
					.catch(err => {
						console.log(err.message)
					});
			},
			getAggrement() {
				this.aggrementVisible = true;
			},
			// 获取验证码
			getCode() {
				captcha({
						captcha_id: 'this.captcha.id'
					})
					.then(res => {
						if (res.code >= 0) {
							this.captcha = res.data;
							this.captcha.img = this.captcha.img.replace(/\r\n/g, '');
						}
					})
					.catch(err => {
						this.$message.error(err.message);
					});
			}
		}
	};
</script>
<style lang="scss" scoped>
	.register {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 20px 0;
	}

	.box-card {
		width: 700px;
		margin: 0 auto;
		display: flex;
		background-color: #ffffff;
		padding: 0 30px 30px 30px;
		flex-direction: column;
		padding-bottom: 256px;
		.register-title {
			border-bottom: 1px solid #f1f1f1;
			text-align: left;
			margin-bottom: 20px;
			font-size: 16px;
			color: $base-color;
			padding: 10px 0;
		}

		.register-account {
			width: 100%;
			text-align: center;
		}

		.code {
			width: 80%;
			text-align: left;
		}

		.el-form {
			margin: 0 30px;

			.captcha {
				vertical-align: top;
				max-width: inherit;
				max-height: 38px;
				line-height: 38px;
				cursor: pointer;
			}
		}

		.xyContent {
			height: 600px;
			overflow-y: scroll;
		}

		.xy {
			margin-left: 110px;
      line-height: 40px;
			//display: flex;
			//justify-content: space-between;
			//align-items: center;
			text-align: left;
			margin-right: 30px;

			.toLogin {
				cursor: pointer;
			}

			.xy-wrap {
				display: flex;
				align-items: center;
				font-size: $ns-font-size-base;
				cursor: pointer;

				.iconfont {
					display: flex;
					align-content: center;
				}

				.content {
					margin-left: 3px;

					b {
						color: $base-color;
					}
				}
			}

			.iconxuanze-duoxuan {
				color: $base-color;
			}
		}

		.el-button {
			margin: 20px 0 0 25px;
			background-color: $base-color;
			color: #ffffff;
			width: calc(100% - 60px);
		}
	}

	.floatLayer-wrap{
		height: 100%;
		width: 100%;
		background-color: rgba(0,0,0,.5);
		z-index: 999;
		position: absolute;
		.reward-wrap {
			width: 400px;
			height: auto;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			.bg-img {
				width: 100%;
				will-change: transform;
			}
			.bg-img-head{
				position: absolute;
				top: -90px;
				width: 100%;

			}
			.bg-img-money{
				position: absolute;
				width: 100%;
				left: -20px;
				top: 80px;
				z-index: 10;
			}

			.wrap {
				width: calc(100% - 1px);
				height: 100%;
				background-color: #ef3030;
				margin-top: -40px;
				padding-bottom: 30px;
				border-bottom-left-radius: 5px;
				border-bottom-right-radius: 5px;
				& > div {
					position: relative;
				}
			}

			.reward-content {
				margin: 0 25px 0 25px;
			}

			.reward-item {
				.head {
					color: #fff;
					text-align: center;
					line-height: 1;
					margin: 10px 0;
				}

				.content {
					display: flex;
					padding: 8px 13px;
					background: #fff;
					border-radius: 5px;
					margin-bottom: 5px;
					.info {
						flex: 1;
					}

					.tip {
						color: #ff222d;
						padding: 5px 0 5px 15px;
						width: 70px;
						line-height: 1.5;
						letter-spacing: 1px;
						border-left: 1px dashed #e5e5e5;
						height: 40px;
						line-height: 40px;
					}

					.num {
						font-size: 26px;
						color: #ff222d;
						font-weight: bolder;
						line-height: 1;
					}
					.coupon-name{
						font-size: 19px;
					}

					.type {
						font-size: $ns-font-size-base;
						margin-left: 5px;
						line-height: 1;
					}

					.desc {
						margin-top: 4px;
						color: $base-color;
						font-size: $ns-font-size-base;
						line-height: 1;
					}
				}
			}

			.btn {
				position: absolute;
				width: calc(100% - 50px);
				bottom: 20px;
				left: 25px;

				.btn-img {
					width: 100%;
				}
			}
		}
	}
	.close-btn{
		text-align: center;
		margin-top: 20px;
		.iconfont{
			color: #fff;
			font-size: 20px;
		}
	}
	.clear{
		content: '';
		display: block;
		clear: both;
	}
</style>
